// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cards-container {
  margin-top: 2rem;
  gap: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  border-top: 5px solid #a64eff;
  width: 263px;
  height: 270px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: transform 0.2s;
}
.card svg {
  font-size: 50px;
  color: #a64eff;
  margin: 0 auto;

}
.card:hover {
  transform: scale(1.05);
}

.card h6 {
  margin: 10px 0;
  font-size: 18px;
  font-weight: 600;
  color: #6a0287;
}

.card .count {
  font-size: 26px;
  font-weight: bold;
  color: #a64eff;
  border-radius: 50%;
  background-color: #ebe0f9;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  box-shadow: inset 5px 5px 15px rgba(137, 35, 196, 0.24);
}
`, "",{"version":3,"sources":["webpack://./src/Dashboard.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,SAAS;EACT,mBAAmB;EACnB,aAAa;EACb,eAAe;EACf,uBAAuB;AACzB;;AAEA;EACE,6BAA6B;EAC7B,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,wCAAwC;EACxC,aAAa;EACb,kBAAkB;EAClB,0BAA0B;AAC5B;AACA;EACE,eAAe;EACf,cAAc;EACd,cAAc;;AAEhB;AACA;EACE,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,kBAAkB;EAClB,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;EACd,uDAAuD;AACzD","sourcesContent":[".cards-container {\n  margin-top: 2rem;\n  gap: 20px;\n  margin-bottom: 20px;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n}\n\n.card {\n  border-top: 5px solid #a64eff;\n  width: 263px;\n  height: 270px;\n  background-color: #fff;\n  border-radius: 10px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  padding: 20px;\n  text-align: center;\n  transition: transform 0.2s;\n}\n.card svg {\n  font-size: 50px;\n  color: #a64eff;\n  margin: 0 auto;\n\n}\n.card:hover {\n  transform: scale(1.05);\n}\n\n.card h6 {\n  margin: 10px 0;\n  font-size: 18px;\n  font-weight: 600;\n  color: #6a0287;\n}\n\n.card .count {\n  font-size: 26px;\n  font-weight: bold;\n  color: #a64eff;\n  border-radius: 50%;\n  background-color: #ebe0f9;\n  width: 60px;\n  height: 60px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin: 0 auto;\n  box-shadow: inset 5px 5px 15px rgba(137, 35, 196, 0.24);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
