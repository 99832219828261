import React from "react";

const Frame = () => {
  return (
    <div>
      <h3>Frmae here...</h3>
      <iframe
        src="https://admin.emailai.world/template/123?description=%22dasdasdashdkasdasjdab%20dskhadkashda%20kdajasdjaskd%20kdjaskdjaskdjask%20%22"></iframe>
    </div>
  );
};
 
export default Frame;
